import React from "react"
import { Box, Typography, Container, styled, Stack, Grid } from "@mui/material"
import {
  ManageAccountsOutlined,
  AcUnitOutlined,
  ComputerOutlined,
  BugReportOutlined,
} from "@mui/icons-material"

import Title from "../../components/Title"
import Footer from "../../components/Footer"
//@ts-ignore
import ImgRecruitmentService from "../../resources/recruitment-service.jpg"

const ListBox = styled(Box)({
  borderRadius: "15px",
  borderWidth: "1px",
  borderColor: "#000000",
  boxShadow: "0px 0px 3px #165EE824",
  display: "flex",
  flexDirection: "column",
  "&:hover": {
    boxShadow: "0px 0px 12px #165EE824",
    cursor: "pointer",
  },
})

export default () => {
  return (
    <div className="App">
      <img
        src={ImgRecruitmentService}
        width="100%"
        height="450"
        style={{ objectFit: "cover" }}
      />
      <Title
        title="EXECUTIVE SEARCH SPECIALISTS"
        subTitle="Recruitment agency with Executive Search Specialists"
      />

      <Container maxWidth="xl" sx={{ marginTop: 2 }}>
        <Box mb={3}>
          <Typography variant="body2">
            Finding the right executives to lead your business is critical to
            success, that is where Savanna HR can help
          </Typography>
          <Typography variant="body2">
            Savanna HR consultants come with multiple years of expertise of
            dealing with Top Talent. We help you with researched information to
            help you make the correct decision. Our consultants help you with a
            detailed persona of the talent you plan to hire, even before you
            start the recruitment process.
          </Typography>

          <Box py={3}>
            <Typography
              variant="h5"
              align="center"
              sx={{
                fontSize: "21px",
                color: "#091188",
                fontWeight: "600",
                marginTop: 3,
              }}
            >
              Our Recruitment Specialist help you
            </Typography>
            <Typography align="center" variant="body2">
              We help you find CEO's, Directors, Vice President, to fill your
              strategic key roles.
            </Typography>
          </Box>
          <Stack flexDirection="row" gap={2} mb={2} mt={2} flexWrap="wrap">
            <ListBox
              height={200}
              width={200}
              justifyContent="center"
              alignItems="center"
            >
              <ManageAccountsOutlined fontSize="large" />
              <Typography variant="body2" align="center">
                Chief Executive officer
              </Typography>
            </ListBox>
            <ListBox
              height={200}
              width={200}
              justifyContent="center"
              alignItems="center"
            >
              <AcUnitOutlined fontSize="large" />
              <Typography variant="body2" align="center">
                Business or department heads
              </Typography>
            </ListBox>
            <ListBox
              height={200}
              width={200}
              justifyContent="center"
              alignItems="center"
            >
              <ComputerOutlined fontSize="large" />
              <Typography variant="body2" align="center">
                Directors / Vice Presidents
              </Typography>
            </ListBox>
            <ListBox
              height={200}
              width={200}
              justifyContent="center"
              alignItems="center"
            >
              <BugReportOutlined fontSize="large" />
              <Typography variant="body2" align="center">
                Senior Management Roles
              </Typography>
            </ListBox>
          </Stack>
          <Box py={3}>
            <Typography
              variant="h5"
              align="center"
              sx={{
                fontSize: "21px",
                color: "#091188",
                fontWeight: "600",
                marginTop: 3,
              }}
            >
              Why Hiring Technical Experts?
            </Typography>
            <Typography align="center" variant="body2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <ListBox p={2}>
                <Typography
                  variant="h4"
                  justifyContent="flex-start"
                  sx={{
                    fontSize: "0.7em",
                    fontWeight: "800",
                    marginBottom: 1,
                  }}
                >
                  Business Advancement
                </Typography>
                <Typography variant="body2">
                  Grow your business and profit with a team of dedicated
                  full-time employees with committed working hours and advanced
                  skills
                </Typography>
              </ListBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <ListBox p={2}>
                <Typography
                  variant="h4"
                  justifyContent="flex-start"
                  sx={{
                    fontSize: "0.7em",
                    fontWeight: "800",
                    marginBottom: 1,
                  }}
                >
                  Business Advancement
                </Typography>
                <Typography variant="body2">
                  Grow your business and profit with a team of dedicated
                  full-time employees with committed working hours and advanced
                  skills
                </Typography>
              </ListBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <ListBox p={2}>
                <Typography
                  variant="h4"
                  justifyContent="flex-start"
                  sx={{
                    fontSize: "0.7em",
                    fontWeight: "800",
                    marginBottom: 1,
                  }}
                >
                  Business Advancement
                </Typography>
                <Typography variant="body2">
                  Grow your business and profit with a team of dedicated
                  full-time employees with committed working hours and advanced
                  skills
                </Typography>
              </ListBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <ListBox p={2}>
                <Typography
                  variant="h4"
                  justifyContent="flex-start"
                  sx={{
                    fontSize: "0.7em",
                    fontWeight: "800",
                    marginBottom: 1,
                  }}
                >
                  Business Advancement
                </Typography>
                <Typography variant="body2">
                  Grow your business and profit with a team of dedicated
                  full-time employees with committed working hours and advanced
                  skills
                </Typography>
              </ListBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <ListBox p={2}>
                <Typography
                  variant="h4"
                  justifyContent="flex-start"
                  sx={{
                    fontSize: "0.7em",
                    fontWeight: "800",
                    marginBottom: 1,
                  }}
                >
                  Business Advancement
                </Typography>
                <Typography variant="body2">
                  Grow your business and profit with a team of dedicated
                  full-time employees with committed working hours and advanced
                  skills
                </Typography>
              </ListBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <ListBox p={2}>
                <Typography
                  variant="h4"
                  justifyContent="flex-start"
                  sx={{
                    fontSize: "0.7em",
                    fontWeight: "800",
                    marginBottom: 1,
                  }}
                >
                  Business Advancement
                </Typography>
                <Typography variant="body2">
                  Grow your business and profit with a team of dedicated
                  full-time employees with committed working hours and advanced
                  skills
                </Typography>
              </ListBox>
            </Grid>
          </Grid>
          <Box py={3}>
            <Typography
              variant="h5"
              align="center"
              sx={{
                fontSize: "21px",
                color: "#091188",
                fontWeight: "600",
                marginTop: 3,
              }}
            >
              Why Choose Savanna HR for Technical Recruitment
            </Typography>
            <Typography align="center" variant="body2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <ListBox p={2}>
                <Typography
                  variant="h4"
                  justifyContent="flex-start"
                  sx={{
                    fontSize: "0.7em",
                    fontWeight: "800",
                    marginBottom: 1,
                  }}
                >
                  Business Advancement
                </Typography>
                <Typography variant="body2">
                  Grow your business and profit with a team of dedicated
                  full-time employees with committed working hours and advanced
                  skills
                </Typography>
              </ListBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <ListBox p={2}>
                <Typography
                  variant="h4"
                  justifyContent="flex-start"
                  sx={{
                    fontSize: "0.7em",
                    fontWeight: "800",
                    marginBottom: 1,
                  }}
                >
                  Business Advancement
                </Typography>
                <Typography variant="body2">
                  Grow your business and profit with a team of dedicated
                  full-time employees with committed working hours and advanced
                  skills
                </Typography>
              </ListBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <ListBox p={2}>
                <Typography
                  variant="h4"
                  justifyContent="flex-start"
                  sx={{
                    fontSize: "0.7em",
                    fontWeight: "800",
                    marginBottom: 1,
                  }}
                >
                  Business Advancement
                </Typography>
                <Typography variant="body2">
                  Grow your business and profit with a team of dedicated
                  full-time employees with committed working hours and advanced
                  skills
                </Typography>
              </ListBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <ListBox p={2}>
                <Typography
                  variant="h4"
                  justifyContent="flex-start"
                  sx={{
                    fontSize: "0.7em",
                    fontWeight: "800",
                    marginBottom: 1,
                  }}
                >
                  Business Advancement
                </Typography>
                <Typography variant="body2">
                  Grow your business and profit with a team of dedicated
                  full-time employees with committed working hours and advanced
                  skills
                </Typography>
              </ListBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <ListBox p={2}>
                <Typography
                  variant="h4"
                  justifyContent="flex-start"
                  sx={{
                    fontSize: "0.7em",
                    fontWeight: "800",
                    marginBottom: 1,
                  }}
                >
                  Business Advancement
                </Typography>
                <Typography variant="body2">
                  Grow your business and profit with a team of dedicated
                  full-time employees with committed working hours and advanced
                  skills
                </Typography>
              </ListBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <ListBox p={2}>
                <Typography
                  variant="h4"
                  justifyContent="flex-start"
                  sx={{
                    fontSize: "0.7em",
                    fontWeight: "800",
                    marginBottom: 1,
                  }}
                >
                  Business Advancement
                </Typography>
                <Typography variant="body2">
                  Grow your business and profit with a team of dedicated
                  full-time employees with committed working hours and advanced
                  skills
                </Typography>
              </ListBox>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </div>
  )
}
