import React from "react"
import { Box } from "@mui/material"

import "./Title.css"

const Title = (props: any) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      width="100%"
      height="200px"
      position="relative"
      fontWeight="bold"
      marginBottom="3em"
    >
      <div>
        <span id="big">{props.title}</span>
        <br />
      </div>
      <span id="small">{props.title}</span>
      <span id="middle">{props.subTitle}</span>
    </Box>
  )
}

export default Title
